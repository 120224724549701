export const consts = {
  GOOGLE_API_KEY: "",
  FDL_TIMEZONE: "Europe/London",
  DEFAULT_MAP_CENTER: {
    lat: 54.5073509,
    lng: -2.4577583,
  },
  DEFAULT_MAP_ZOOM: 5
}

export const COLORS = {
  primary: '#32006E',
  danger: '#DF382C',
  warning: '#EFB73E',
  success: '#38B44A',
  gray: '#495057',
  darkGray: '#333333',
  lightGray: '#868e96',
  white: '#ffffff',
  blue: '#007bff',
  textlight: '#adb5bd',
}


export const UserRoleValues = {
  SUPER_ADMIN: 'super_admin',
  ADMIN: 'admin',
  HR_MANAGER: 'hr_manager',
  REGION_MANAGER: 'region_manager',
  DEPOT_MANAGER: 'depot_manager',
  SORTATION_DEPOT_ASSISTANT: 'sortation_depot_assistant',
  CALL_CENTER_ASSISTANT: 'call_center_assistant',
  SECURITY: 'security',
  AGENT: 'agent',
  PARTNER: 'partner',
  SUPPLIER_ADMIN: 'supplier_admin',
  SUPPLIER_OPERATOR: 'supplier_operator',
  SALES_REP: 'sales_rep',
  DRIVER: 'driver',
}

export const UserRoleTitles = {
  [UserRoleValues.SUPER_ADMIN]: 'Super Admin',
  [UserRoleValues.ADMIN]: 'Admin',
  [UserRoleValues.HR_MANAGER]: 'HR',
  [UserRoleValues.REGION_MANAGER]: 'Region Manager',
  [UserRoleValues.DEPOT_MANAGER]: 'Depot Manager',
  [UserRoleValues.SORTATION_DEPOT_ASSISTANT]: 'Sortation Depot Assistant',
  [UserRoleValues.SECURITY]: 'Security',
  [UserRoleValues.AGENT]: 'Agent',
  [UserRoleValues.PARTNER]: 'Partner',
  [UserRoleValues.CALL_CENTER_ASSISTANT]: 'Customer Service Assistant',
  [UserRoleValues.SUPPLIER_ADMIN]: 'Supplier Admin',
  [UserRoleValues.SUPPLIER_OPERATOR]: 'Supplier Operator',
  [UserRoleValues.SALES_REP]: 'Sales Rep',
  [UserRoleValues.DRIVER]: 'Driver',
}

export const GenderValues = {
  MALE: 'male',
  FEMALE: 'female',
}

export const GenderTitles = {
  [GenderValues.MALE]: 'Male',
  [GenderValues.FEMALE]: 'Female',
}

// ---------------------- Order Values --------------------------

export const OrderStatusValues = {
  CREATED: 'created',
  AWAITING_COLLECTION_FROM_SUPPLIER: 'awaiting_collection_from_supplier',
  READY_FOR_COLLECTION: 'ready_for_collection',

  ROUTE_ASSIGNED_FOR_COLLECTION: 'route_assigned_for_collection',
  DRIVER_ASSIGNED_FOR_COLLECTION: 'driver_assigned_for_collection',
  OUT_FOR_COLLECTION: 'out_for_collection',
  COLLECTED: 'collected',
  NOT_COLLECTED: 'not_collected',

  RECEIVED_AT_COLLECTION_DEPOT: 'received_at_collection_depot',
  IN_TRANSIT_TO_SORTATION_DEPOT: 'in_transit_to_sortation_depot',
  RECEIVED_AT_SORTATION_DEPOT: 'received_at_sortation_depot',
  IN_TRANSIT_TO_DELIVERY_DEPOT: 'in_transit_to_delivery_depot',
  RECEIVED_AT_LAST_DEPOT: 'received_at_last_depot',

  ROUTE_ASSIGNED_FOR_DELIVERY: 'route_assigned_for_delivery',
  DRIVER_ASSIGNED_FOR_DELIVERY: 'driver_assigned_for_delivery',
  // AWAITING_PICKUP_FOR_DELIVERY: 'awaiting_pickup_for_delivery',
  OUT_FOR_DELIVERY: 'out_for_delivery',
  DELIVERED: 'delivered',
  PARTIALLY_DELIVERED: 'partially_delivered',
  NOT_DELIVERED: 'not_delivered',
  INVOICED: 'invoiced',
  FINISHED: 'finished',

  MISSING: 'missing',
  DELAYED: 'delayed',
  DAMAGED_IN_TRANSIT: 'damaged_in_transit',
  LOST: 'lost',

  SENT_TO_SENDER: 'sent_to_sender',
  RETURN_FINISHED: 'return_finished',

  DELIVERY_DATE_CHANGED: 'delivery_date_changed',

  FINAL_RETURN: 'final_return',
}

export const OrderStatusTitles = {
  [OrderStatusValues.CREATED]: 'Created',
  [OrderStatusValues.AWAITING_COLLECTION_FROM_SUPPLIER]: 'Awaiting Collection from Supplier',
  [OrderStatusValues.READY_FOR_COLLECTION]: 'Ready for Collection',

  [OrderStatusValues.ROUTE_ASSIGNED_FOR_COLLECTION]: 'Route Assigned for Collection',
  [OrderStatusValues.DRIVER_ASSIGNED_FOR_COLLECTION]: 'Driver Assigned for Collection',
  [OrderStatusValues.OUT_FOR_COLLECTION]: 'Out for Collection',
  [OrderStatusValues.COLLECTED]: 'Collected',
  [OrderStatusValues.NOT_COLLECTED]: 'Not Collected',

  [OrderStatusValues.RECEIVED_AT_COLLECTION_DEPOT]: 'Received at Collection Depot',
  [OrderStatusValues.IN_TRANSIT_TO_SORTATION_DEPOT]: 'In Transit to Sortation Depot',
  [OrderStatusValues.RECEIVED_AT_SORTATION_DEPOT]: 'Received at Sortation Depot',
  [OrderStatusValues.IN_TRANSIT_TO_DELIVERY_DEPOT]: 'In Transit to Delivery Depot',
  [OrderStatusValues.RECEIVED_AT_LAST_DEPOT]: 'Received at Delivery Depot',

  [OrderStatusValues.ROUTE_ASSIGNED_FOR_DELIVERY]: 'Route Assigned for Delivery',
  [OrderStatusValues.DRIVER_ASSIGNED_FOR_DELIVERY]: 'Awaiting Pickup for Delivery',
  // [OrderStatusValues.AWAITING_PICKUP_FOR_DELIVERY]: 'Awaiting Pickup for Delivery',
  [OrderStatusValues.OUT_FOR_DELIVERY]: 'Out for Delivery',
  [OrderStatusValues.DELIVERED]: 'Delivered',
  [OrderStatusValues.PARTIALLY_DELIVERED]: 'Partially Delivered',
  [OrderStatusValues.NOT_DELIVERED]: 'Not Delivered',
  [OrderStatusValues.INVOICED]: 'Invoiced',
  [OrderStatusValues.FINISHED]: 'Finished',

  [OrderStatusValues.MISSING]: 'Parcels Missing',
  [OrderStatusValues.DELAYED]: 'Delivery Delayed',
  [OrderStatusValues.DAMAGED_IN_TRANSIT]: 'Damaged in Transit',
  [OrderStatusValues.LOST]: 'Parcels Lost',

  [OrderStatusValues.SENT_TO_SENDER]: 'Sent to Sender',
  [OrderStatusValues.RETURN_FINISHED]: 'Returned to Sender',

  [OrderStatusValues.DELIVERY_DATE_CHANGED]: 'Delivery Date Changed',

  [OrderStatusValues.FINAL_RETURN]: 'Final Return',
}

export const OrderStatusColors = {
  [OrderStatusValues.CREATED]: COLORS.gray,
  [OrderStatusValues.AWAITING_COLLECTION_FROM_SUPPLIER]: COLORS.gray,
  [OrderStatusValues.READY_FOR_COLLECTION]: COLORS.gray,

  [OrderStatusValues.ROUTE_ASSIGNED_FOR_COLLECTION]: COLORS.primary,
  [OrderStatusValues.DRIVER_ASSIGNED_FOR_COLLECTION]: COLORS.primary,
  [OrderStatusValues.OUT_FOR_COLLECTION]: COLORS.warning,
  [OrderStatusValues.COLLECTED]: COLORS.success,
  [OrderStatusValues.NOT_COLLECTED]: COLORS.danger,

  [OrderStatusValues.RECEIVED_AT_COLLECTION_DEPOT]: COLORS.primary,
  [OrderStatusValues.IN_TRANSIT_TO_SORTATION_DEPOT]: COLORS.gray,
  [OrderStatusValues.RECEIVED_AT_SORTATION_DEPOT]: COLORS.primary,
  [OrderStatusValues.IN_TRANSIT_TO_DELIVERY_DEPOT]: COLORS.gray,
  [OrderStatusValues.RECEIVED_AT_LAST_DEPOT]: COLORS.primary,

  [OrderStatusValues.ROUTE_ASSIGNED_FOR_DELIVERY]: COLORS.primary,
  [OrderStatusValues.DRIVER_ASSIGNED_FOR_DELIVERY]: COLORS.blue,
  // [OrderStatusValues.AWAITING_PICKUP_FOR_DELIVERY]: COLORS.warning,
  [OrderStatusValues.OUT_FOR_DELIVERY]: COLORS.warning,
  [OrderStatusValues.DELIVERED]: COLORS.success,
  [OrderStatusValues.PARTIALLY_DELIVERED]: COLORS.warning,
  [OrderStatusValues.NOT_DELIVERED]: COLORS.danger,
  [OrderStatusValues.INVOICED]: COLORS.success,
  [OrderStatusValues.FINISHED]: '#bbbbbb',

  [OrderStatusValues.MISSING]: COLORS.danger,
  [OrderStatusValues.DELAYED]: COLORS.lightGray,
  [OrderStatusValues.DAMAGED_IN_TRANSIT]: COLORS.danger,
  [OrderStatusValues.LOST]: COLORS.danger,

  [OrderStatusValues.SENT_TO_SENDER]: COLORS.lightGray,
  [OrderStatusValues.RETURN_FINISHED]: COLORS.success,

  [OrderStatusValues.DELIVERY_DATE_CHANGED]: COLORS.blue
}

export const OrderTypeValues = {
  DELIVERY: 'delivery',
  RETURN: 'return',
}

export const OrderTypeTitles = {
  [OrderTypeValues.DELIVERY]: 'Delivery',
  [OrderTypeValues.RETURN]: 'Return',
}


// ---------------------- Recipient Values --------------------------
export const RecipientTypeValues = {
  BUSINESS: 'business',
  RESIDENTIAL: 'residential',
}

export const RecipientTypeTitles = {
  [RecipientTypeValues.BUSINESS]: 'Business',
  [RecipientTypeValues.RESIDENTIAL]: 'Residential',
}

export const RecipientStatusValues = {
  ACTIVE: 'active',
  INACITVE: 'inactive',
  DELETED: 'deleted'
}

export const RecipientStatusTitles = {
  [RecipientStatusValues.ACTIVE]: 'Active',
  [RecipientStatusValues.INACITVE]: 'Inactive',
  [RecipientStatusValues.DELETED]: 'Deleted'
}

// ---------------------- Cod Method Values --------------------------

export const CODMethodValues = {
  CASH: 'cash',
  BANK_TRANSFER: 'bank_transfer',
  FDL_CREDIT: 'fdl_credit'
}

export const CODMethodTitles = {
  [CODMethodValues.CASH]: 'Cash',
  [CODMethodValues.BANK_TRANSFER]: 'Bank Transfer',
  [CODMethodValues.FDL_CREDIT]: 'Credit',
}

// ---------------------- Stop Values --------------------------

export const FailedDeliveryReasonValues = {
  NONE: 'none',
  NO_CUSTOMER: 'no_customer',
  REJECTED: 'rejected',
  SHOP_CLOSED: 'shop_closed',
  DAMAGED_IN_TRANSIT: 'damaged_in_transit',
  MISSING: 'missing',
  CANNOT_ACCESS: 'cannot_access',
  WRONG_ADDRESS: 'wrong_address',
  OUT_OF_DELIVERY_AREA: 'out_of_delivery_area',
}

export const FailedDeliveryReasonTitles = {
  [FailedDeliveryReasonValues.NONE]: 'None',
  [FailedDeliveryReasonValues.NO_CUSTOMER]: 'Customer Unavailable',
  [FailedDeliveryReasonValues.REJECTED]: 'Rejected by Customer',
  [FailedDeliveryReasonValues.SHOP_CLOSED]: 'Business Closed',
  [FailedDeliveryReasonValues.DAMAGED_IN_TRANSIT]: 'Damaged',
  [FailedDeliveryReasonValues.MISSING]: 'Missing',
  [FailedDeliveryReasonValues.CANNOT_ACCESS]: 'Cannot Access',
  [FailedDeliveryReasonValues.WRONG_ADDRESS]: 'Wrong Address',
  [FailedDeliveryReasonValues.OUT_OF_DELIVERY_AREA]: 'out_of_delivery_area',
}

export const StopTypeValues = {
  PICKUP: 'pickup',
  COLLECT: 'collect',
  DELIVERY: 'delivery',
  RETURN: 'return',
  CUSTOMER: 'customer',
  NL_COLLECTION_POINT: 'nl_collection_point'
}

export const StopTypeTitles = {
  [StopTypeValues.PICKUP]: 'PICKUP',
  [StopTypeValues.COLLECT]: 'COLLECT',
  [StopTypeValues.DELIVERY]: 'DELIVERY',
  [StopTypeValues.RETURN]: 'RETURN',
  [StopTypeValues.CUSTOMER]: 'CUSTOMER',
}


export const StopStatusValues = {
  DEFAULT: 'default',
  IN_TRAVEL: 'in_travel',
  ARRIVED: 'arrived',
  FINISHED: 'finished',
}

export const StopStatusTitles = {
  [StopStatusValues.DEFAULT]: 'DEFAULT',
  [StopStatusValues.IN_TRAVEL]: 'IN TRAVEL',
  [StopStatusValues.ARRIVED]: 'ARRIVED',
  [StopStatusValues.FINISHED]: 'FINISHED',
}

// Parcel Status Constants

export const ParcelStatusValues = {
  CREATED: 'created',

  COLLECTED_BY_DRIVER: 'collected_by_driver',
  RECEIVED_AT_COLLECTION_DEPOT: 'received_at_collection_depot',

  IN_TRANSIT_TO_SORTATION_DEPOT: 'in_transit_to_sortation_depot',
  IN_TRANSIT_TO_SORTATION_DEPOT_ND: 'in_transit_to_sortation_depot_nd',
  RECEIVED_AT_SORTATION_DEPOT: 'received_at_sortation_depot',
  IN_TRANSIT_TO_DELIVERY_DEPOT: 'in_transit_to_delivery_depot',

  RECEIVED_AT_DEPOT: 'received_at_depot',
  IN_DELIVERY: 'in_delivery',
  DELIVERED: 'delivered',
  NOT_DELIVERED: 'not_delivered',
  ACCEPTED: 'accepted',
  RETURNED: 'returned',
  SENT_TO_SENDER: 'sent_to_sender',
  SENT_TO_NEW_ORDER: 'sent_to_new_order',
  KEEP_AT_DEPOT: 'keep_at_depot',
  DESTROYED: 'destroyed',

  RETURNED_TO_SENDER: 'returned_to_sender',
}

export const ParcelStatusTitles = {
  [ParcelStatusValues.CREATED]: 'Created',
  [ParcelStatusValues.COLLECTED_BY_DRIVER]: 'Collected by Driver',
  [ParcelStatusValues.RECEIVED_AT_COLLECTION_DEPOT]: 'Received at Collection Depot',

  [ParcelStatusValues.IN_TRANSIT_TO_SORTATION_DEPOT]: 'In Transit to Sortation Depot',
  [ParcelStatusValues.IN_TRANSIT_TO_SORTATION_DEPOT_ND]: 'In Transit to Sortation Depot - ND',
  [ParcelStatusValues.RECEIVED_AT_SORTATION_DEPOT]: 'Received at Sortation Depot',
  [ParcelStatusValues.IN_TRANSIT_TO_DELIVERY_DEPOT]: 'In Transit to Delivery Depot',

  [ParcelStatusValues.RECEIVED_AT_DEPOT]: 'Received at Delivery Depot',
  [ParcelStatusValues.IN_DELIVERY]: 'In Delivery',
  [ParcelStatusValues.DELIVERED]: 'Delivered',
  [ParcelStatusValues.NOT_DELIVERED]: 'Not Delivered',
  [ParcelStatusValues.ACCEPTED]: 'Accepted',
  [ParcelStatusValues.RETURNED]: 'Returned',
  [ParcelStatusValues.SENT_TO_SENDER]: 'Sent to Sender',
  [ParcelStatusValues.SENT_TO_NEW_ORDER]: 'Sent to New Order',
  [ParcelStatusValues.KEEP_AT_DEPOT]: 'Keep at the Depot',
  [ParcelStatusValues.DESTROYED]: 'Destroyed',

  [ParcelStatusValues.RETURNED_TO_SENDER]: 'Returned to Sender',
}

export const ParcelStatusColors = {
  [ParcelStatusValues.CREATED]: COLORS.gray,
  [ParcelStatusValues.COLLECTED_BY_DRIVER]: COLORS.primary,
  [ParcelStatusValues.IN_TRANSIT_TO_SORTATION_DEPOT]: COLORS.warning,
  [ParcelStatusValues.RECEIVED_AT_SORTATION_DEPOT]: COLORS.primary,
  [ParcelStatusValues.IN_TRANSIT_TO_DELIVERY_DEPOT]: COLORS.warning,
  [ParcelStatusValues.RECEIVED_AT_DEPOT]: COLORS.primary,
  [ParcelStatusValues.IN_DELIVERY]: COLORS.warning,
  [ParcelStatusValues.DELIVERED]: COLORS.success,
  [ParcelStatusValues.NOT_DELIVERED]: COLORS.danger,
  [ParcelStatusValues.ACCEPTED]: COLORS.success,
  [ParcelStatusValues.RETURNED]: COLORS.danger,
  [ParcelStatusValues.SENT_TO_SENDER]: COLORS.gray,
  [ParcelStatusValues.SENT_TO_NEW_ORDER]: COLORS.primary,
  [ParcelStatusValues.KEEP_AT_DEPOT]: COLORS.gray,
  [ParcelStatusValues.DESTROYED]: COLORS.darkGray,
}

export const ParcelDestroyReasonValues = {
  DAMAGED: 'damaged',
  OUT_OF_DATE: 'out_of_date',
}

export const ParcelDestroyReasonTitles = {
  [ParcelDestroyReasonValues.DAMAGED]: 'Damaged',
  [ParcelDestroyReasonValues.OUT_OF_DATE]: 'Out of Date',
}

// Parcel State Constants

export const ParcelStateValues = {
  NOT_RECEIVED: 'not_received',
  NORMAL: 'normal',
  DAMAGED_IN_UNLOADING: 'damaged_in_unloading',
  DAMAGED_IN_LOADING: 'damaged_in_loading',
  DAMAGED_IN_TRANSIT: 'damaged_in_transit',
  MISSING: 'missing',
  CUSTOMER_REFUSED: 'customer_refused',
}

export const ParcelStateTitles = {
  [ParcelStateValues.NOT_RECEIVED]: 'Not Received',
  [ParcelStateValues.NORMAL]: 'Normal',
  [ParcelStateValues.DAMAGED_IN_UNLOADING]: 'Damaged in Unloading',
  [ParcelStateValues.DAMAGED_IN_LOADING]: 'Damaged in Loading',
  [ParcelStateValues.DAMAGED_IN_TRANSIT]: 'Damaged in Transit',
  [ParcelStateValues.MISSING]: 'Missing',
  [ParcelStateValues.CUSTOMER_REFUSED]: 'Customer Refused',
}

// ---------------------- Pallet Values --------------------------
export const PalletStatusValues = {
  CREATED: 'created',
  IN_TRANSIT: 'in_transit',
  RECEIVED: 'received'
}

export const PalletStatusTitles = {
  [PalletStatusValues.CREATED]: 'CREATED',
  [PalletStatusValues.IN_TRANSIT]: 'IN TRANSIT',
  [PalletStatusValues.RECEIVED]: 'RECEIVED',
}

export const PalletDirection = {
  IN: 'in',
  OUT: 'out',
}

// ------------------------ Route Values ------------------------

export const RouteStatusValues = {
  CREATED: 'created',
  COLLECTED: 'collected',
  FINISHED: 'finished',
  DEBRIEF: 'debrief',
}

export const RouteStatusTitles = {
  [RouteStatusValues.CREATED]: 'Created',
  [RouteStatusValues.COLLECTED]: 'In Delivery',
  [RouteStatusValues.FINISHED]: 'Finished',
  [RouteStatusValues.DEBRIEF]: 'Debriefed',
}

export const RouteStatusColors = {
  [RouteStatusValues.CREATED]: COLORS.lightGray,
  [RouteStatusValues.COLLECTED]: COLORS.warning,
  [RouteStatusValues.FINISHED]: COLORS.success,
  [RouteStatusValues.DEBRIEF]: COLORS.gray,
}

// ------------------------ Support Values ------------------------

export const TicketRelationValues = {
  ORDER: 0,
  RETURN: 1,
  LABEL: 2,
  FINANCE: 10,
  TECHNICAL: 20
}

export const TicketRelationTitles = {
  [TicketRelationValues.ORDER]: 'Order',
  [TicketRelationValues.RETURN]: 'Return',
  [TicketRelationValues.LABEL]: 'Labels',
  [TicketRelationValues.FINANCE]: 'Financial Issue',
  [TicketRelationValues.TECHNICAL]: 'Technical Issue',
}

export const TicketPriorityValues = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2,
}

export const TicketPriorityTitles = {
  [TicketPriorityValues.LOW]: 'Low',
  [TicketPriorityValues.MEDIUM]: 'Medium',
  [TicketPriorityValues.HIGH]: 'High',
}

export const TicketStatusValues = {
  CREATED: 0,
  IN_PROGRESS: 1,
  FINISHED: 10,
  CANCELLED: 11
}

export const TicketStatusTitles = {
  [TicketStatusValues.CREATED]: 'Created',
  [TicketStatusValues.IN_PROGRESS]: 'In Progress',
  [TicketStatusValues.FINISHED]: 'Finished',
  [TicketStatusValues.CANCELLED]: 'Cancelled',
}


// -------------------- Shipment Leave Place -----------------

export const LeaveTypeValues = {
  CUSTOMER: 'customer',
  // For Business
  MAIL_ROOM: 'mail_room',
  RECEPTION: 'reception',
  SHOP_ASSISTANT: 'shop_assistant',
  // For Residential
  NEIGHBOR: 'neighbor',
  FRONT_PORCH: 'front_porch',
  REAR_PORCH: 'rear_porch',
  GARDEN: 'garden',
  BEHIND_WHEELIE_BIN: 'behind_wheelie_bin',
  SHED_GARDEN_HOUSE: 'shed_garden_house',
  LETTERBOX: 'letterbox',
}

export const LeaveTypeTitles = {
  [LeaveTypeValues.CUSTOMER]: 'Customer',
  // For Business
  [LeaveTypeValues.MAIL_ROOM]: 'Mail Room',
  [LeaveTypeValues.RECEPTION]: 'Reception',
  [LeaveTypeValues.SHOP_ASSISTANT]: 'Shop Assistant',
  // For Residential
  [LeaveTypeValues.NEIGHBOR]: 'Neighbour',
  [LeaveTypeValues.FRONT_PORCH]: 'Front Porch',
  [LeaveTypeValues.REAR_PORCH]: 'Rear Porch',
  [LeaveTypeValues.GARDEN]: 'Rear Garden',
  [LeaveTypeValues.BEHIND_WHEELIE_BIN]: 'Behind Wheelie Bin',
  [LeaveTypeValues.SHED_GARDEN_HOUSE]: 'Shed Garden House',
  [LeaveTypeValues.LETTERBOX]: 'Letter Box',
}


// ------------------------ Envelope Values ------------------------

export const EnvelopeStatusValues = {
  CREATED: 'created',
  IN_TRANSIT: 'in_transit',
  RECEIVED: 'received',
}

export const EnvelopeStatusTitles = {
  [EnvelopeStatusValues.CREATED]: 'At Depot',
  [EnvelopeStatusValues.IN_TRANSIT]: 'In Transit',
  [EnvelopeStatusValues.RECEIVED]: 'Received',
}

export const EnvelopeStatusColors = {
  [EnvelopeStatusValues.CREATED]: COLORS.gray,
  [EnvelopeStatusValues.IN_TRANSIT]: COLORS.danger,
  [EnvelopeStatusValues.RECEIVED]: COLORS.success,
}

// -------------------- Warehouse Logo Status -----------------

export const WarehouseLogoStatusValues = {
  DEFAULT: 'default',
  IN_REVIEW: 'in_review',
  APPROVED: 'approved',
  DENIED: 'denied',
}

export const WarehouseLogoStatusTitles = {
  [WarehouseLogoStatusValues.DEFAULT]: 'Default',
  [WarehouseLogoStatusValues.IN_REVIEW]: 'In Review',
  [WarehouseLogoStatusValues.APPROVED]: 'Approved',
  [WarehouseLogoStatusValues.DENIED]: 'Denied',
}

export const WarehouseLogoStatusColors = {
  [WarehouseLogoStatusValues.DEFAULT]: COLORS.gray,
  [WarehouseLogoStatusValues.IN_REVIEW]: COLORS.gray,
  [WarehouseLogoStatusValues.APPROVED]: COLORS.success,
  [WarehouseLogoStatusValues.DENIED]: COLORS.danger,
}


// -------------------- Notification Targets -----------------

export const NotificationTargetValues = {
  MANAGERS: 'managers',
  SUPPLIERS: 'suppliers',
}

export const NotificationTargetTitles = {
  [NotificationTargetValues.MANAGERS]: 'Managers',
  [NotificationTargetValues.SUPPLIERS]: 'Suppliers',
}


// -------------------- Service  -----------------

export const ServiceTargetValues = {
  BUSINESS_CUSTOMER: 'business_customers',
  PRIVATE_CUSTOMER: 'private_customers'
}

export const ServiceTargetTitles = {
  [ServiceTargetValues.BUSINESS_CUSTOMER]: 'Business Customers',
  [ServiceTargetValues.PRIVATE_CUSTOMER]: 'Private Customers',
}

export const ServiceStatusValues = {
  ACTIVE: 'active',
  PENDING: 'pending',
  INACTIVE: 'inactive',
  TERMINATED: 'terminated',
}

export const ServiceStatusTitles = {
  [ServiceStatusValues.ACTIVE]: 'Active',
  [ServiceStatusValues.PENDING]: 'Pending',
  [ServiceStatusValues.INACTIVE]: 'Inactive',
  [ServiceStatusValues.TERMINATED]: 'Terminated',
}

export const ServiceStatusColors = {
  [ServiceStatusValues.ACTIVE]: '#3ac47d',
  [ServiceStatusValues.PENDING]: '#fd7e14',
  [ServiceStatusValues.INACTIVE]: '#d92550',
  [ServiceStatusValues.TERMINATED]: '#aaaaaa',
}


// -------------------- Transport Type  -----------------

export const TransportTypeValues = {
  DELIVERY: 'delivery',
  COLLECTION: 'collection'
}

export const TransportTypeTitles = {
  [TransportTypeValues.DELIVERY]: 'Delivery',
  [TransportTypeValues.COLLECTION]: 'Collection',
}


// -------------------- Currency Type  -----------------

export const CurrencyValues = {
  GBP: 'GBP',
  EUR: 'EUR',
  USD: 'USD'
}

export const CurrencyTitles = {
  [CurrencyValues.GBP]: 'GBP',
  [CurrencyValues.EUR]: 'EUR',
  [CurrencyValues.USD]: 'USD',
}

export const CurrencySymbols = {
  [CurrencyValues.GBP]: '£',
  [CurrencyValues.EUR]: '€',
  [CurrencyValues.USD]: '$',
}

// -------------------- Discount  -----------------

export const DiscountStatusValues = {
  CREATED: 'created',
  ACTIVE: 'active',
  EXPIRED: 'expired',
  CANCELLED: 'cancelled',
}

export const DiscountStatusTitles = {
  [DiscountStatusValues.CREATED]: 'Not Started',
  [DiscountStatusValues.ACTIVE]: 'Active',
  [DiscountStatusValues.EXPIRED]: 'Expired',
  [DiscountStatusValues.CANCELLED]: 'Cancelled',
}

export const DiscountStatusColors = {
  [DiscountStatusValues.CREATED]: COLORS.lightGray,
  [DiscountStatusValues.ACTIVE]: '#3ac47d',
  [DiscountStatusValues.EXPIRED]: '#d92550',
  [DiscountStatusValues.CANCELLED]: '#aaaaaa',
}

// -------------------- Invoice  -----------------

export const InvoiceStatusValues = {
  CREATED: 'created',
  APPROVED_BY_MANAGER: 'approved_by_manager',
  SENT_TO_CUSTOMER: 'sent_to_customer',
  APPROVED_BY_CUSTOMER: 'approved_by_customer',
  DISPUTED_BY_CUSTOMER: 'disputed_by_customer',
  PAID: 'paid',
  PAYMENT_CONFIRMED: 'payment_confirmed',
  PAYMENT_DELAYED: 'payment_delayed',
}

export const InvoiceStatusTitles = {
  [InvoiceStatusValues.CREATED]: 'CREATED',
  [InvoiceStatusValues.APPROVED_BY_MANAGER]: 'APPROVED BY MANAGER',
  [InvoiceStatusValues.SENT_TO_CUSTOMER]: 'SENT TO CUSTOMER',
  [InvoiceStatusValues.APPROVED_BY_CUSTOMER]: 'APPROVED BY CUSTOMER',
  [InvoiceStatusValues.DISPUTED_BY_CUSTOMER]: 'DISPUTED',
  [InvoiceStatusValues.PAID]: 'PAID',
  [InvoiceStatusValues.PAYMENT_CONFIRMED]: 'PAYMENT CONFIRMED',
}

export const InvoiceStatusColors = {
  [InvoiceStatusValues.CREATED]: COLORS.gray,
  [InvoiceStatusValues.APPROVED_BY_MANAGER]: COLORS.blue,
  [InvoiceStatusValues.SENT_TO_CUSTOMER]: COLORS.gray,
  [InvoiceStatusValues.APPROVED_BY_CUSTOMER]: COLORS.primary,
  [InvoiceStatusValues.DISPUTED_BY_CUSTOMER]: COLORS.danger,
  [InvoiceStatusValues.PAID]: COLORS.warning,
  [InvoiceStatusValues.PAYMENT_CONFIRMED]: COLORS.success,
}

export const ParcelPriceRangeTypeValues = {
  NONE: 'none',
  PRICE_RANGE: 'price_range',
  SURCHARGE: 'surcharge',
  CUSTOM: 'custom',
  EXCLUDED: 'excluded',
  ERROR: 'error'
}

export const DefaultValues = {
  allDepotOption: { id: null, code: "(All Depots)" }
}

export const SortationState = {
  NORMAL: "normal",
  MISSORTED: "missorted"
}


// -------------------- Credit Notes  -----------------

export const CreditNoteStatusValues = {
  CREATED: 'created',
  APPROVED_BY_MANAGER: 'approved_by_manager',
}

export const CreditNoteStatusTitles = {
  [CreditNoteStatusValues.CREATED]: 'CREATED',
  [CreditNoteStatusValues.APPROVED_BY_MANAGER]: 'APPROVED BY MANAGER',
}

// ---------------------- Driver Type Values --------------------------

export const DriverTypeValues = {
  MULTI_DROP: 'multi_drop',
  COLLECTION: 'collection',
  NIGHT: 'night'
}

export const DriverTypeTitles = {
  [DriverTypeValues.MULTI_DROP]: 'Multi-Drop',
  [DriverTypeValues.COLLECTION]: 'Collection',
  [DriverTypeValues.NIGHT]: 'Night'
}

// ---------------------- Preset Route Values --------------------------

export const PresetRouteTypeValues = {
  DAY: 'day',
  NIGHT: 'night',
}

export const PresetRouteTypeTitles = {
  [PresetRouteTypeValues.DAY]: 'Day',
  [PresetRouteTypeValues.NIGHT]: 'Night'
}

// ---------------------- Preset Route Values --------------------------

export const CollectionPointTypeValues = {
  COLLECTION: 'collection',
  NIGHT: 'night',
  DEPOT: 'depot'
}


export const CollectionRouteTypeValues = {
  COLLECTION: 'collection',
  NIGHT: 'night'
}

export const CollectionRouteTypeTitles = {
  [CollectionRouteTypeValues.COLLECTION]: 'Collection',
  [CollectionRouteTypeValues.NIGHT]: 'Night',
}

export const CollectionRouteSubtypeValues = {
  PRESET: 'preset',
  FREE: 'free',
}

export const CollectionRouteSubtypeTitles = {
  [CollectionRouteSubtypeValues.PRESET]: 'Preset',
  [CollectionRouteSubtypeValues.FREE]: 'Free Route'
}


// ---------------------- Notification Values --------------------------

export const NotificationTypeValues = {
  EMAIL: 'email',
  SMS: 'sms'
}

export const NotificationTypeTitles = {
  [NotificationTypeValues.EMAIL]: 'Email',
  [NotificationTypeValues.SMS]: 'SMS'
}

// ---------------------- Depot Routing Engine --------------------------

export const RoutingEngineValues = {
  ROUTE4ME: 'route4me',
  OSRM: 'osrm',
  FAR_EYE: 'far_eye'
}

export const RoutingEngineTitles = {
  [RoutingEngineValues.ROUTE4ME]: 'Route4Me',
  [RoutingEngineValues.OSRM]: 'OSRM',
  [RoutingEngineValues.FAR_EYE]: 'FarEye',
}

export const RoutingMethodValues = {
  NORMAL: 'normal',
  CUBE: 'cube_routing',
  CUBE2: 'cube_routing_2',
  MT: 'master_tracing',
  SEQUENTIAL: 'sequential',
  ALGORITHM_2: 'algorithm_2',
  FAR_EYE: 'far_eye',
}

export const RoutingMethodTitles = {
  [RoutingMethodValues.NORMAL]: 'Normal',
  [RoutingMethodValues.MT]: 'MT',
  [RoutingMethodValues.SEQUENTIAL]: 'Algorithm',
  [RoutingMethodValues.ALGORITHM_2]: 'Algorithm 2'
}

export const NLCollectionTypeValues = {
  TO_HUB_DELIVERY: 'to_hub_delivery',
  MULTI_COLLECTION: 'multi_collection',
}

export const NLCollectionTypeTitles = {
  [NLCollectionTypeValues.TO_HUB_DELIVERY]: 'To Hub Delivery',
  [NLCollectionTypeValues.MULTI_COLLECTION]: 'Multi-Collection',
}

export const JobTypeValues = {
  PART_TIME: 'part_time',
  FULL_TIME: 'full_time',
  OCCASIONAL: 'occasional',
  SELF_EMPLOYED: 'self_employed',
}

export const JobTypeTitles = {
  [JobTypeValues.PART_TIME]: 'Part Time',
  [JobTypeValues.FULL_TIME]: 'Full Time',
  [JobTypeValues.OCCASIONAL]: 'Occasional',
  [JobTypeValues.SELF_EMPLOYED]: 'Self Employed',
}

